import React from "react"
import Layout from "../components/layout/layout"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
      <div class="massive-wrapper">
          <div class="container">
              <div class="content-wrapper">
                  <p className="support-info">TvVote mobile application - <br />which is operated by „TVFON” EOOD, a Bulgarian company,
with UIC: 205561797,<br />having its seat and registered address at: Republic of Bulgaria, Sofia city, postal code 1164, <br />26 “James Bourchier” Boulevard, floor 1, apartment 2.</p>
                <h4 className="support-info">CONTACT US AT: <a href="mailto:7967680@gmail.com">7967680@GMAIL.COM</a></h4>
                <h4 className="support-info"><Link to="/privacy-policy/">PRIVACY POLICY</Link></h4>
                <h4 className="support-info"><Link to="/terms-and-conditions/">TERMS</Link></h4>
                <div className="support-wrapper">
                  <form method="post" action="#" className="support-form">
                <div className="contact-form-wrapper">
                   <div className="form-placeholder">
                    <input type="text" name="name" id="name" placeholder="Your full name" className="placeholder"/>
                </div>
                
                <div className="form-placeholder">
                    <input type="email" name="email" id="email" placeholder="Your business email" className="placeholder"/>
                </div>

                <div className="form-placeholder">
                    <input type="text" name="subject" id="subject" placeholder="Your company" className="placeholder"/>
                </div>

                <div className="form-placeholder">
                    <textarea name="message" id="message" rows="5" placeholder="Your message" className="placeholder"/>
                  </div>
                  
                  <div className="submit-btn support-btn">
                    <button type="submit" className="button">Get started</button>
                  </div>
                  
                </div>
                </form>
                </div>
              </div>
          </div>
      </div>

  </Layout>
  )

export default IndexPage